import React, { useState } from "react";
import { apiCall } from "../../_services/apiCall";  
import config from "../../config/config.json"

const CreateSetorQuestionSectorModuleModal = ({ module, getReportingQuestion,closeAddTopicPopup}) => {
  const [items, setItems] = useState([{ questionTitle: "", emissionFactor: "" }]);

  const handleAddMore = () => {
    setItems([...items, { questionTitle: "", emissionFactor: "" }]);
  };

  const handleInputChange = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = event.target.value;
    setItems(values);
  };

  const handleSubmit = async (e) => {
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}createEmissionQuestion`,
      {},
      { sectorId: module?.id, questions: items },
      "POST"
    );
    if (isSuccess) {
    //   window.location.href = config.baseURL + `/#/sector_module`;
      getReportingQuestion()
        closeAddTopicPopup();
    }
  };

  return (
    <div className="container">
      {items.map((item, index) => (
        <div key={index} className="form-group">
          <label>Title</label>
          <input
            type="text"
            name="questionTitle"
            value={item?.questionTitle}
            onChange={(event) => handleInputChange(index, event)}
            className="form-control"
            placeholder="Enter title"
          />
          <label>Emission Factor</label>
          <input
            type="text"
            name="emissionFactor"
            value={item?.emissionFactor}
            onChange={(event) => handleInputChange(index, event)}
            className="form-control"
            placeholder="Enter emission factor"
          />
        </div>
      ))}
      <div className="d-flex justify-content-between mt-2">
        <button onClick={handleAddMore} className="btn btn-primary">
          Add More
        </button>
        <button onClick={handleSubmit} className="btn btn-success">
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreateSetorQuestionSectorModuleModal;
