import config from "../../../config/config.json";
import { getStore } from "../../../utils/UniversalFunction";
// import swal from "sweetalert";

export const companyService = {
    getCompany
};


async function getCompany() {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.ADMIN_API_URL}getCompanyList`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

function headersWithAuthNew(method, token) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };
  return requestOptions;
}

