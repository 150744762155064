import React, { useEffect, useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../_services/apiCall";
import { authenticationService } from "../../_services/authentication";
import config from "../../config/config.json";
import "../Sector_Question_Manage/control.css";
import CreateSectorQuestionModal from "../Company_Super_Admin/Questions Module/CreateSectionModal";
import { type } from "jquery";
import CreateSetorQuestionSectorModuleModal from "./CreateSetorQuestionSectorModuleModal";

export const SectorQuestion = (props) => {
  const {
    sectorData,
    financial_year_id,
    handleKPIChangeHandler,
    handleAddTopicQuestionChangeHandler,
  } = props;
  const [topics, setTopics] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [updateQuestion, setUpdateQuestion] = useState(false);
  const [addTopic, setAddTopic] = useState(false);
  const [updateModule, setUpdateModule] = useState(false);
  const [item, setItem] = useState([{}]);
  const [title, setTitle] = useState("");
  const [emissionFactor,setEmissionFactor] = useState("")
  const [id, setId] = useState();
  const closeUpdateFrameworkPopup = () => setUpdateQuestion(false);
  const showAddTopicPopup = () => setAddTopic(true);
  const showUpdateTopicPopup = () => setUpdateQuestion(true);
  const closeAddTopicPopup = () => setAddTopic(false);
  const closeUpdateModulePopup = () => setUpdateModule(false);

  const getReportingQuestion = async (id) => {
    if (sectorData?.id) {
      const { isSuccess, data } = await apiCall(
        `${config.ADMIN_API_URL}getEmissionQuestion`,
        {},
        { sector_id: sectorData?.id, type: "ALL" },
        "GET"
      );
      setTopics(data?.data?.reverse());
      console.log("question", data?.data);
    }
  };

  const deleteQuestion = async (item) => {
    console.log("", item);
    let obj = {};
    obj.id = item?.id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteEmissionQuestion`,
      {},
      obj,
      "POST"
    );

    getReportingQuestion();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("clicked");
    let obj = {};
    obj.sector_id = id;
    obj.emissionFactor = emissionFactor;
    obj.title = title;
    console.log("update", obj);
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateEmissionQuestion`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      //   window.location.href = config.baseURL + `/#/sector_module`;
      getReportingQuestion();
      closeUpdateModulePopup();
    }
  };

  const handleEditQuestion = (item) => {
    console.log("item", item);  
    setUpdateQuestion(true);
    setItem(item);
    setId(item?.id);
    setTitle(item?.title);
    setEmissionFactor(item?.emission_factor);
  };

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    if (props.sectorData.id) {
      getReportingQuestion(props.sectorData.id);
    }
  }, [props.sectorData.id]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">{sectorData.title}</h4>
          <span className="global_link mx-0">
            <>
              <div
                onClick={showAddTopicPopup}
                className="mx-3 add_framework_control"
              >
                <i className="fas fa-plus-square fs-lg" title="Add Topic"></i>
                &nbsp;&nbsp;
              </div>
            </>
          </span>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Topics"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Question Title</th>
              <th>Emission Factor</th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {topics?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item?.title}</td>
                <td>{item?.emission_factor}</td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => handleEditQuestion(item)}>
                        <i className="fas fa-plus-square"></i>&nbsp;&nbsp; Edit
                        Question
                      </Dropdown.Item>

                      <Dropdown.Item onClick={(e) => deleteQuestion(item)}>
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; Delete
                        Question
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* ------------ Update Topic --------- */}
      <Modal show={updateQuestion} onHide={closeUpdateFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              value={title} // assuming `title` is your state variable for the title
              onChange={(e) => setTitle(e.target.value)} // assuming `setTitle` is the setter function for the title
            />
          </div>
          <div className="form-group">
            <label>Emission Factor</label>
            <input
              type="text"
              className="form-control"
              value={emissionFactor} // assuming `emissionFactor` is your state variable for the emission factor
              onChange={(e) => setEmissionFactor(e.target.value)} // assuming `setEmissionFactor` is the setter function for the emission factor
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit" onClick={handleSubmit}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={addTopic} onHide={closeAddTopicPopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Form.Label>Add Question</Form.Label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <CreateSetorQuestionSectorModuleModal
              getReportingQuestion={getReportingQuestion}
              closeAddTopicPopup={closeAddTopicPopup}
              module={sectorData}
              // financial_year_id={financial_year_id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ height: 65 }}> </Modal.Footer>
      </Modal>
      {/* <QuestionModal show={addTopic} onHide={closeAddTopicPopup} handleSubmit={handleSubmit} setTitle={setTitle} setTopicType={setTopicType} /> */}
    </div>
  );
};
