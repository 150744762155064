import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../../_services/apiCall";
import { authenticationService } from "../../../_services/authentication";
import config from "../../../config/config.json";
import "../../Sector_Question_Manage/control.css";

export const Questions = (props) => {
  const {
    financial_year_id,
    handleTopicChangeHandler,
    handleAddFrameworkQuestionChangeHandler,
  } = props;

  const [modules, setModules] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const location = useLocation();
  const [data] = useState(location?.state?.item);
  const [title, setTitle] = useState("");
  const [id, setId] = useState();
  const [updateModule, setUpdateModule] = useState(false);
  const [addModule, setAddModule] = useState(false);
  const closeUpdateModulePopup = () => setUpdateModule(false);
  const showAddModulePopup = () => setAddModule(true);
  const showUpdateModulePopup = (id) => {
  
    setId(id);
    setUpdateModule(true);
  };
  const closeAddModulePopup = () => setAddModule(false);

  const [moduleTitle, setModuleTitle] = useState("");

  const handleModuleSubmit = async (e) => {
    e.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}createQuestionModule`,
      {},
      { title: moduleTitle },
      "POST"
    );
    if (isSuccess) {
      setTitle("");
      callGetModule();
      closeAddModulePopup();
    }
  };

  const callGetModule = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getQuestionModule`,
      {},
      { type: "ALL" },
      "GET"
    );
    setModules(data?.data?.reverse());

  };

  const deleteModules = async (e, item) => {
    let obj = {};
    obj.id = item?.id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteQuestionModule`,
      {},
      obj,
      "POST"
    );
    callGetModule();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("clicked");
    let obj = {};
    obj.id = id;
    obj.title = title;
    console.log("update", obj);
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}editQuestionModule`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      window.location.href = config.baseURL + `/#/reporting_module`;
      callGetModule();
      closeUpdateModulePopup();
    }
  };


  const handleClickTopicComponent = (topicComponentData, activeTab) => {
    handleTopicChangeHandler(topicComponentData, activeTab);
  };


  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    callGetModule();
  }, [financial_year_id?.id]);
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    callGetModule();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">
            <strong>Modules List</strong>
          </h4>

          <div
            onClick={showAddModulePopup}
            className="mx-3 add_framework_control"
          >
            <i className="fas fa-plus-square fs-lg" title="Add Framework"></i>
            &nbsp;&nbsp;
          </div>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Module"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Module</th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          {modules && modules?.length > 0 ? (
            <tbody>
              {modules &&
                modules?.map((item, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{item?.title}</td>
                    <td className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={(e) => {
                       
                              handleClickTopicComponent(item, "topic");
                            }}
                          >
                            <i className="fas fa-eye"></i>&nbsp;&nbsp; View
                            Question
                          </Dropdown.Item>

                          <>
                            <Dropdown.Item
                              onClick={(e) => showUpdateModulePopup(item.id)}
                            >
                              <i className="far fa-edit"></i>&nbsp;&nbsp; Edit
                              Module
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={(e) => deleteModules(e, item)}
                            >
                              <span style={{ color: "red" }}>
                                <i className="fas fa-trash"></i>&nbsp;&nbsp;
                                Delete Module
                              </span>
                            </Dropdown.Item>
                          </>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td>--</td>
                <td>No Data Found</td>
                <td>--</td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      {/* ------------ Update Framework --------- */}
      <Modal show={updateModule} onHide={closeUpdateModulePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form name="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="questionHeading"
                value={title}
                placeholder="Enter Module Title"
                name="heading"
                onChange={(e) => {
                  setTitle(e.target.value);
               
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateModulePopup}
          >
            Cancel
          </button>
          <button
            className="link_bal_next"
            type="submit"
            onClick={handleSubmit}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* ------------ Add Framework --------- */}
      <Modal show={addModule} onHide={closeAddModulePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Add Module </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group pb-3">
            <label htmlFor="title" className="mb-2">
              Module Heading
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Module Title"
              name="heading"
              onChange={(e) => setModuleTitle(e.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeAddModulePopup}
          >
            Cancel
          </button>
          <button
            className="link_bal_next"
            type="submit"
            onClick={handleModuleSubmit}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
